import { useStaticQueryWithPreview } from "@tedgustaf/gatsby-plugin-sanity-preview";
import { graphql, useStaticQuery } from "gatsby";
import React, { createContext } from "react";
import { PageNodeQuery } from "../config/build/models/PageNodeQuery";
import fallback from "../localization/localizationFiles/fallback.json";
import { Translations } from "../localization/Translations";
import { translationsPreviewQueryProjection } from "../preview/staticQueries/translations";
import { isNullOrWhiteSpace } from "../shared/string";

/**
 * Name of a language, in said language. Mainly used for
 * language selectors.
 */
export const localLanguageNames: Translations = {
  "en": "English",
  "en_us": "English",
  "fr-be": "Français",
  "fr-ch": "Français",
  "nl-be": "Nederlands",
  "de-ch": "Deutsch",
  "it-ch": "Italiano",
  "sv-se": "Svenska"
};

export type LocalizationContextProviderProps = {
  children?: React.ReactNode;
  currentLanguage?: string;
};

const LocalizationContext = createContext<Translations>({});

const LocalizationContextProvider = ({
  currentLanguage,
  children
}: LocalizationContextProviderProps) => {
  const result = useStaticQueryWithPreview({
    staticQueryData: useStaticQuery<PageNodeQuery<Translations>>(graphql`
      query getTranslations {
        queryResult: allSanityTranslations {
          nodes {
            i18nLang
            address
            allCalendarEvents
            allPests
            allPressReleases
            allReports
            allYears
            allCategories
            article
            attackingFoodTextiles
            attackingWood
            australia
            behavior
            biting
            blog
            blogArticle
            brandImage
            pressImage
            canada
            chooseCountry
            clickSelectFile
            close
            countryOfResidence
            crawling
            currentLocation
            date
            displayIn
            downloadImage
            dragAndDrop
            email
            error
            financialEvent
            flying
            gnawing
            goToOfficeLink
            gridTitle
            hiRes
            hits
            home
            hongkong
            informationType
            invalidDate
            invalidNumber
            invalidPostalCode
            japan
            lastXYears
            learnTheTipsButton
            listTitle
            loadMoreButton
            locator
            lowRes
            management
            mapSearchPlaceholder
            maxFileSize
            maxLength
            maxValue
            media
            message
            minLength
            minValue
            moreThan8Legs
            name
            newZealand
            next
            no
            noLegs
            noWings
            numberOfLegs
            numberOfWings
            office
            officeHours
            offices
            officesInSameRegionSubtitle
            officesInSameRegionTitle
            or
            orCall
            orderByDate
            orderByRelevance
            other
            past
            person
            pest
            pest_2Legs
            pest_2Wings
            pest_4Legs
            pest_4Wings
            pest_6Legs
            pest_8Legs
            pestIdentifier
            phoneNumber
            policyCheckboxLabel
            presentation
            pressRelease
            pressReleases
            proceed
            ratingDescription0
            ratingDescription1
            ratingDescription2
            ratingDescription3
            ratingDescription4
            recaptcha
            regulatoryPressRelease
            regulatoryPressReleases
            relatedServicesAndLocatorsSubtitle
            relatedServicesAndLocatorsTitle
            report
            reports
            required
            reviewsReviewsCount
            reviewsAllReviewsLinkText
            reviewsSeeFullReviewLinkText
            searchAll
            searchLabel
            searchPlaceholder
            searchSubmit
            searchTitle
            seeAll
            selectLanguage
            sendEmail
            sendMessage
            service
            showOnMapButton
            southAfrica
            submit
            submitInvalid
            submitted
            submitting
            subscribeSubmit
            sweden
            title
            tooManyFiles
            troublingPeople
            typeOfIssue
            upcoming
            usa
            visitorAddress
            yes
          }
        }
      }
    `),
    query: translationsPreviewQueryProjection
  });

  let translations = result.queryResult.nodes.find(
    translation => translation.i18nLang === currentLanguage
  );

  if (translations) {
    // Fallback for each missing translation.
    // Use "fallback" as reference since it *should* contain all field names
    for (const field in fallback) {
      if (isNullOrWhiteSpace(translations[field])) {
        translations[field] = fallback[field];
      }
    }
  } else {
    console.warn(
      `No translations for language '${currentLanguage}' found, using fallback instead`
    );
    translations = fallback;
  }

  return (
    <LocalizationContext.Provider value={translations}>
      {children}
    </LocalizationContext.Provider>
  );
};

export { LocalizationContext, LocalizationContextProvider };
